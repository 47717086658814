import React from 'react';
import { Link } from 'react-router-dom';
import './home.css';

const Home = ({ isDeveloper }) => {
  const mode = isDeveloper ? 'Developer' : 'Designer';
  const gradient = isDeveloper
    ? 'linear-gradient(#4871BB, #1C6DFF)' // Blue for Developer
    : 'linear-gradient(#CB450C, #FF7F48)'; // Orange for Designer

  return (
    <div className="homepage">
      <div className="background"></div> {/* Separate div for background */}
      <main className="content">
        <h1>
          Ahmad Jamous is a{' '}
          <span
            className={`highlight ${isDeveloper ? 'developer' : 'designer'}`}
            style={{
              backgroundImage: gradient,
            }}
          >
            {mode}
          </span>{' '}
          <span className="mobile-break"></span>
          based&nbsp;in <span className="location">Toronto,&nbsp;Canada.</span>
        </h1>

        <p className={`subtitle ${isDeveloper ? 'developer' : 'designer'}`}>
          Creative Problems, Innovative Solutions.
        </p>
        <p className="description">
          With a creative background in multimedia design and technical expertise
          in AI, software, and hardware development, I bring a versatile set of
          skills to help you tackle any challenge—creative or technical.
        </p>
        <Link to="/contact">
          <button
            className={`get-in-touch-button ${isDeveloper ? 'blue' : 'orange'}`}
          >
            Get in touch!
          </button>
        </Link>
      </main>
    </div>
  );
};

export default Home;
